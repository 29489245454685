<template>
  <div class="k-gold">
    <div class="main">
      <van-field v-model="value" label="房间号:" placeholder="请输入房间号"/>
      <van-field
          v-model="message"
          rows="3"
          autosize
          label="内容"
          type="textarea"
          maxlength="50"
          placeholder="内容"
          show-word-limit
      />
      <van-button type="primary" size="large" class="btn" @click="sub">推送消息</van-button>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
export default {
  name: 'kGold',
  data() {
    return {
      value: '',
      message: ''
    }
  },
  created() {

  },
  methods: {
    sub: function () {
      console.log("123")
      let _this = this
      if (_this.value == '') {
        _this.$toast('请输入房间号');
        return
      }
      if (_this.message == '') {
        _this.$toast('请输入推送内容');
        return
      }
      let msg = 'MESSAGE' + '|' + _this.value + '|' + _this.message
      _this.axios.post('api/servers/ddpush_terminal_wensha',  qs.stringify({
        place_id: 'ycs36586',
        content: msg,
      })).then(data => {
        console.log(data)
        _this.$toast(data.msg);
        _this.message = ''
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
<style scoped>
.k-gold {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  background-color: skyblue;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.btn {
  margin-top: 20px;
}
</style>